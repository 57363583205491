<template>
  <div class="tableBox">
    <el-form :inline="true" :model="form" class="demo-form-inline">
      <el-form-item label="厂站名称">
        <el-input v-model="form.stationPlant"></el-input>
      </el-form-item>
      <el-form-item label="装机容量kWp">
        <el-input v-model="form.designVolume"></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="form.powerstationstatus">
          <el-option label="正常" value="1"></el-option>
          <el-option label="故障" value="2"></el-option>
          <el-option label="全部" value="3"></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="厂站规划">
        <el-input v-model="form.stationPlan"></el-input>
      </el-form-item> -->
      <el-form-item label="厂站地址">
        <el-input v-model="form.address"></el-input>
      </el-form-item>
      <el-form-item label="并网类型">
        <el-input v-model="form.user"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary">查询</el-button>
      </el-form-item>
    </el-form>

    <el-table
      :data="tableData"
      border
      style="width: 100%"
      max-height="520"
      :max-width="800"
      class="tablebox-bottom"
      @cell-click="handleCellClick"
    >
      <el-table-column
        label="状态"
        align="center"
        width="50"
        prop="powerStationStatus"
      >
        <template v-slot="{ row }">
          <div v-if="row.powerStationStatus == 1" class="success"></div>
          <div v-if="row.powerStationStatus == 2" class="danger"></div>
        </template>
      </el-table-column>
      <!-- <el-table-column
        label="场站规划"
        align="center"
        width="120"
        prop="stationPlant"
      >
      </el-table-column> -->
      <el-table-column label="实景" align="center" width="120" prop="logo">
        <template v-slot="{ row }">
          <img class="img" :src="row.logo" alt="" />
        </template>
      </el-table-column>
      <el-table-column
        label="名称"
        show-overflow-tooltip
        align="center"
        prop="powerStationName"
      >
      </el-table-column>
      <el-table-column
        label="地址"
        show-overflow-tooltip
        align="center"
        width="140"
        prop="address"
      >
      </el-table-column>

      <el-table-column
        label="装机容量(kw)"
        align="center"
        width="100"
        prop="volume"
      >
        <template v-slot="{ row }">
          {{ row.volume ? addCommas(row.volume) : 0 }}
        </template>
      </el-table-column>

      <el-table-column
        label="当前功率(kw)"
        width="100"
        prop="currEfficiency"
        align="center"
      >
        <template v-slot="{ row }">
          {{
            row.currEfficiency
              ? row.currEfficiency > 1000
                ? addCommas(row.currEfficiency / 1000)
                : addCommas(row.currEfficiency)
              : 0
          }}
        </template>
      </el-table-column>

      <el-table-column label="当日发电量(度)" align="center" width="110">
        <template v-slot="{ row }">
          {{ row.currDayPowerNum ?  addCommas(row.currDayPowerNum) : 0 }}
        </template>
      </el-table-column>

      <!-- <el-table-column
        label="本月发电量（Kwh）"
        align="center"
        width="140"
        prop="currMonthPowerNum"
      >
      </el-table-column> -->

      <el-table-column
        label="累计发电量(度)"
        align="center"
        width="110"
        prop="totalPowerNum"
      >
        <template v-slot="{ row }">
          {{ row.totalPowerNum ? addCommas(row.totalPowerNum)  : 0 }}
        </template>
      </el-table-column>

      <el-table-column
        label="当日收益（元）"
        align="center"
        width="110"
        prop="currDayIncome"
      >
        <template v-slot="{ row }">
          {{ row.currDayIncome ?  row.currDayIncome : 0 }}
        </template>
      </el-table-column>
      <el-table-column label="联系人" align="center" width="80" prop="linkMan">
      </el-table-column>

      <el-table-column label="电话" align="center" width="100" prop="linkPhone">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      powerStationCode: localStorage.getItem("powerStationCode"),
      tableData: [],
      form: {
        stationPlant: "", //名称
        designVolume: "", //装机容量
        powerstationstatus: "", //状态
        stationPlan: "", //厂站规划
        address: "", //厂站地址
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    querySearch() {},
    handleCellClick(row, column, event) {
      this.$router.push(`/monitor/monitor/detailItem?areaCode=${row.areaCode}`);
    },
    // 获取数据
    async getData() {
      const res = await this.$http.post("/powerStation/queryOne", {
        powerStationCode: this.powerStationCode,
      });
      if (res.data.code == 200) {
        const res2 = await this.$http.post(
          "/powerStationDevice/queryByPowerStation",
          {
            powerStationCode: this.powerStationCode,
          }
        );
        if (res2.data.code == 200) {
          let mergedObj = Object.assign({}, res.data.data, res2.data.data);
          console.log(mergedObj, 176);
          this.tableData = [mergedObj];
        }
      }
    },
    addCommas(number) {
    // 将数字转换为字符串，并判断是否有小数部分
    let parts = number.toString().split('.');
    let integerPart = parts[0];
    let decimalPart = parts.length > 1 ? '.' + parts[1] : '';

    // 使用正则表达式，每三位加一个逗号
    let regex = /\B(?=(\d{3})+(?!\d))/g;
    integerPart = integerPart.replace(regex, ',');

    // 返回格式化后的字符串
    return integerPart + decimalPart;
}
  },
};
</script>

<style lang="scss" scoped>
.success {
  width: 10px;
  height: 10px;
  background-color: #00ff00;
  border-radius: 50%;
  margin: auto;
}
.danger {
  width: 10px;
  height:10px;
  background-color: #ff0000;
  border-radius: 50%;
  margin: auto;
}

.body {
  background: #223f6c;
  height: 87vh;
  padding: 29px;
  .home-class {
    height: 100%;
    padding: 2px 2px 2px 2px;
    background-color: #2d5981;
    box-sizing: border-box;
    padding: 29px;
    .hand-class {
      display: flex;
      text-align: right;
      float: right;
      margin: 10px 0;
      img {
        width: 20px;
        height: 20px;
        box-sizing: border-box;
        margin: 0 5px;
      }
    }
  }
}

::v-deep .el-table--small .el-table__cell {
  padding: 0 !important;
}

/deep/ .el-input__inner {
  border: 1px solid rgba(151, 179, 203, 0.55);
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  color: #f2f2f2;
  text-align: left;
}
/deep/ .el-table--border,
.el-table--group {
  border: 1px solid rgba(151, 179, 203, 0.55);
}
/deep/ .el-table--border th {
  background-color: #21527e;

  border: 1px solid rgba(151, 179, 203, 0.55);
  box-sizing: border-box;
  font-family: "微软雅黑", sans-serif;
  color: #ffffff;
}
/deep/ .el-table td {
  background-color: #2d5981;
  border: 1px solid rgba(151, 179, 203, 0.55);
  box-sizing: border-box;
  font-family: "微软雅黑", sans-serif;
  color: #ffffff;
}
/deep/ .el-table__body-wrapper {
  background: #2d5981;
}

.tableBox {
  margin-top: 20px;
  // height: 400px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  .demo-form-inline{}
  .tablebox-bottom{
    flex-grow: 1;
    background-color: transparent;
  }
}

.img {
  width: 100px;
  height: 76px;
}

::v-deep .el-form-item--small .el-form-item__label {
  color: #fff !important;
  // line-height: 40px !important;
}

::v-deep .el-input__inner {
  height: 30px !important;
  line-height: 30px !important;
  width: 120px !important;
}
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #223f6c !important;
}
</style>
