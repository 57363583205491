export const topChartOption = {
  tooltip: {
    trigger: 'item', // 标题
    formatter: '{b}: {d}%' // 数据的显示方式
  },
  title: {
    text: '27',
    subtext: '厂站总数',
    textStyle: {
      fontSize: 15,
      color: "#97b3cb",
      fontWeight: 600
    },
    subtextStyle: {
      fontSize: 11,
      color: "#97b3cb",
      fontWeight: 400
    },
    textAlign: "center", //图例文字居中显示
    x: "33%",   //距离左边的距离
    y: "32%"    //距离上边的距离
  },
  legend: {
    orient: 'vertical',
    top: "29%",
    left: "65%",
    itemHeight: 10, // 修改
    itemWidth: 14, // 修改
    textStyle: {
      fontSize: 12,
      color: "#97b3cb",
      rich: {
        a: {
          color: '#00c97b',
          fontWeight: 700
        },
        b: {
          color: '#ff6666',
          fontWeight: 700
        },
        c: {
          color: '#97b3cb',
          fontWeight: 700
        }
      },

    },

    data: ['正常', '故障', '断链'],

  },
  color: ["#00c97b", "#Ff6666", "#999999"],
  series: [
    {
      type: 'pie',
      center: ['35%', '50%'],
      radius: ['70%', '80%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'center'
      },
      labelLine: {
        show: false
      },
      emphasis: {
        label: {
          show: false,
          //   fontSize: '20',
          //   fontWeight: 'bold'
        }
      },
      data: [
        { value: 24, name: '正常' },
        { value: 3, name: '故障' },
        { value: 0, name: '断链' },
      ]
    }
  ]
}
