<template>
  <div class="container">
    <div class="head-box">
      <!-- 发电统计 -->
      <li class="item2">
        <div class="item-head">
          <div class="left-head">
            <i class="el-icon-office-building"></i>
            <span class="head-title">发电统计</span>
          </div>
          <div class="right-head">
            <i class="el-icon-office-building"></i>
          </div>
        </div>
        <div class="item-third">
          <div class="center-circle">
            <div class="circle-text">
              <span v-if="electricity.currDayPowerNum" class="num">{{
                electricity.currEfficiency > 1000
                  ? (electricity.currEfficiency / 1000).toFixed(0)
                  : electricity.currEfficiency.toFixed(0)
              }}</span>
              <span v-else class="num">0w</span>
              <span class="unit" style="font-size: 14px">{{
                electricity.currEfficiency > 1000 ? "MW" : "KW"
              }}</span>
              <!-- <span class="text-class" style="font-size: 10px">发电功率</span> -->
            </div>
          </div>
          <div class="bottom-text">
            <div class="daily"></div>
            <span class="label">电量</span>
            <span class="num">{{
              electricity.currDayPowerNum
                ? Number(electricity.currDayPowerNum).toFixed(0)
                : 0
            }}</span>
            <span class="unit">度</span>
          </div>
        </div>
      </li>
      <!-- <li class="item2">
        <div class="item-head">
          <div class="left-head">
            <i class="el-icon-office-building"></i>
            <span class="head-title">发电统计</span>
          </div>
          <div class="right-head">
            <i class="el-icon-office-building"></i>
          </div>
        </div>
        <div class="item-third">
          <div class="center-circle">
            <div class="circle-text">
              <span class="num">{{obj.currEfficiency}}</span>
              <span class="unit">MV</span>
            </div>
          </div>
          <div class="bottom-left">
            <div class="bottom-left-item">
              <div class="yuan">日</div>
              电量：<span class="bottom-left-item-color">{{obj.currDayPowerNum}}</span> 万度
            </div>
            <div class="bottom-left-item">
              <div class="yuan">月</div>
              电量：<span class="bottom-left-item-color">{{obj.monthPowerNum}}</span> 万度
            </div>
            <div class="bottom-left-item">
              <div class="yuan">年</div>
              电量：<span class="bottom-left-item-color">{{obj.yearPowerNum}}</span> 万度
            </div>
          </div>
        </div>
      </li> -->
      <!-- 厂站状态 -->
      <!-- <li class="item2">
        <div class="item-head">
          <div class="left-head">
            <i class="el-icon-office-building"></i>
            <span class="head-title">厂站状态</span>
          </div>
          <div class="right-head">
            <i class="el-icon-office-building"></i>
          </div>
        </div>
        <div class="item-fourth flex-grow">
          <div ref="topChart" id="topChart" class="fourth-left"></div>
        </div>
      </li> -->
      <li class="item2">
        <div class="item-head">
          <div class="left-head">
            <i class="el-icon-office-building"></i>
            <span class="head-title">厂站状态</span>
          </div>
          <!-- <div class="right-head">
              1
            </div> -->
        </div>
        <div style="padding: 10px">
          <div class="list">
            <div v-for="(item, index) in topList" :key="index">
              <div class="item-title">
                <span>{{ item.title }}</span> <span>{{ item.num }}台</span>
              </div>
              <div class="progress-bar">
                <div
                  class="progress"
                  :style="{ width: (item.num / totalNum) * 100 + '%' }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <!-- 实时告警 -->
      <li class="item2">
        <div class="item-head">
          <div class="left-head">
            <i class="el-icon-office-building"></i>
            <span class="head-title">实时告警</span>
          </div>
          <div class="right-head">
            <i class="el-icon-office-building"></i>
          </div>
        </div>
        <div class="item-fourth flex-grow">
          <ul class="fourth-items">
            <li class="item" v-for="(item, index) in realTime" :key="index">
              <div class="label" v-if="item.alarmlevel == 1">
                提示告警（{{ item.alarmCount }}条）
              </div>
              <div class="label" v-if="item.alarmlevel == 2">
                次要告警（{{ item.alarmCount }}条）
              </div>
              <div class="label" v-if="item.alarmlevel == 3">
                重要告警（{{ item.alarmCount }}条）
              </div>
              <div class="label" v-if="item.alarmlevel == 4">
                紧急告警（{{ item.alarmCount }}条）
              </div>
              <div class="progress">
                <el-progress
                  :show-text="false"
                  :percentage="item.alarmCount * 1"
                  :color="customColorMethod"
                />
              </div>
            </li>
          </ul>
        </div>
      </li>
      <!-- 发电排名（等效小时） -->
      <li class="item2">
        <div class="item-head">
          <div class="left-head">
            <el-icon><OfficeBuilding /></el-icon>
            <span class="head-title">发电排名（等效小时）</span>
          </div>
          <div class="right-head">
            <el-icon><ArrowRight /></el-icon>
          </div>
        </div>
        <div class="item-fourth flex-grow">
          <ul class="fourth-items">
            <li class="item" v-for="(item, index) in ranking" :key="index">
              <div class="label">
                {{ item.powerStationName }}
              </div>
              <div class="progress">
                <el-progress
                  :show-text="false"
                  :percentage="item.equivalentHours * 1"
                  :color="customColorMethod"
                />
              </div>
            </li>
          </ul>
        </div>
      </li>
    </div>

    <Table></Table>
  </div>
</template>

<script>
import { topChartOption } from "./echartsOptions/topChart";
import Table from "../components/detail/table.vue";
import * as echarts from "echarts";
export default {
  components: { Table },
  mounted() {
    this.init();
  },
  data() {
    return {
      powerStationCode: localStorage.getItem("powerStationCode"),
      realTime: [],
      obj: {},
      ranking: [],
      electricity: {}, //统计
      topList: [
        { title: "正常运行", num: 0, color: "#63a103" },
        { title: "设备关闭", num: 0, color: "#02a7f0" },
        { title: "故障停机", num: 0, color: "#655f7d" },
        { title: "通讯中断", num: 0, color: "#8c9da8" },
      ],
    };
  },
    computed: {
    totalNum() {
      // 计算总数
      return this.topList.reduce((total, item) => total + item.num, 0);
    },
  },
  created() {
    this.getGElectricity();
    this.getCount()
    // Promise.all([this.getPCczAlarm(), this.getPCcz(),this.PCgeneTopdg()]);
  },
  methods: {
    init() {
      setTimeout(() => {
        this.$refs.topChart = echarts.init(document.getElementById("topChart"));
        let option = topChartOption;
        this.$refs.topChart.setOption(option);
      }, 200);
    },
    customColorMethod(percentage) {
      if (percentage < 30) {
        return "#D9001B";
      }
      if (percentage < 70) {
        return "#e6a23c";
      }
      return "#67c23a";
    },
    // 获取厂子的实时报告
    // async getPCczAlarm() {
    //   const {
    //     data: { data },
    //   } = await this.$http.post("/RealMonitoring/PCczAlarm", {});
    //   this.realTime = data.filter((item) =>
    //     ["1", "2", "3", "4"].includes(item.alarmlevel)
    //   );
    // },
    // //
    // async getPCcz() {
    //   const {
    //     data: { data },
    //   } = await this.$http.post("/RealMonitoring/PCcz",{powerStationCode:this.powerStationCode});
    //   this.obj = data;
    // },
    //   // 获取发电排名
    // async PCgeneTopdg() {
    //   const {
    //     data: { data },
    //   } = await this.$http.post("/RealMonitoring/PCgeneTopdg", {  });
    //   this.ranking = data;
    // },
    // 发电统计currEfficiency
    async getGElectricity() {
      const res = await this.$http.post(
        "/powerStationDevice/queryByPowerStation",
        { powerStationCode: this.powerStationCode }
      );
      if (res.data.code == 200) {
        this.electricity = res.data.data;
      } else {
        this.$message.error(res.data);
      }
    },

    // 获取厂站状态
    async getCount() {
      const res = await this.$http.post("/powerStationDevice/queryList", {
        powerStationCode: this.powerStationCode,
      });
      console.log(res, 73);
      if (res.data.code == 200) {
        this.dataList = res.data.data;
        // 运行
        this.topList[0].num = this.dataList.filter(
          (item) => item.runingStatus == 1
        ).length;
        // 关闭
        this.topList[1].num = this.dataList.filter(
          (item) => item.runingStatus == 0
        ).length;
        //故障
        this.topList[2].num = this.dataList.filter(
          (item) => item.runingStatus == 2
        ).length;
        // 通讯中断
        this.topList[3].num = this.dataList.filter(
          (item) => item.connectStatus == 0
        ).length;
      }
    },
  },
  beforeDestory() {
    this.$refs.topChart.dispose ? this.$refs.topChart.dispose() : "";
  },
};
</script>

<style lang="scss" scoped>
.container {
  background-color: #223f6c;
  padding: 10px;
  box-sizing: border-box;
  border: none;
  width: 100%;
  height: calc(100vh - 85px);
  display: flex;
  flex-direction: column;
  .yuan {
    width: 20px;
    height: 20px;
    border: 1px solid #02a7f0;
    border-radius: 50%;
    text-align: center;
    color: #02a7f0;
    margin-right: 10px;
  }

  .head-box {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .item2 {
      width: 25%;
      background-color: #2d5981 !important;
      border: 1px solid #02a7f0;
    }
    > li {
      display: flex;
      flex-direction: column;
      background-color: rgba(19, 44, 69, 1);
      // width: calc((100% - 10px) / 6);
      width: 25%;
      border: 1px solid #026795;
      border-radius: 1px;
      margin: 0 5px;
      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
      i {
        color: #02a7f0;
      }
      .item-bottom {
        box-sizing: border-box;
        padding: 10px;
        .line {
          padding-top: 20px;
          padding-bottom: 10px;
          display: flex;
          position: relative;
          justify-content: space-between;
          &::before {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 0;
            border: 1px dashed #263f58;
          }
          .line-left {
            color: #97b3cb;
            margin-left: 10px;
            font-weight: 600;
            font-size: 18px;
            &::after {
              content: ":";
              padding-left: 10px;
              font-size: 18px;
              font-weight: 500;
            }
          }
          .line-right {
            margin-right: 10px;
            color: #95f204;
            .num {
              font-weight: 700;
              font-size: 20px;
            }
            .unit {
              font-size: 16px;
              font-weight: 400;
              padding-left: 10px;
            }
          }
          .line-blue {
            color: #02a7f0;
          }
        }
      }
      .item-head {
        display: flex;
        box-sizing: border-box;
        border-bottom: 1px solid #02a7f0;
        padding: 10px;
        justify-content: space-between;
        align-items: center;
        .left-head {
          display: flex;
          align-items: center;
          .head-title {
            color: #02a7f0;
            font-size: 18px;
            font-weight: 600;
            padding-left: 10px;
          }
        }
      }

      .item-third,
      .flex-grow {
        display: flex;
        // flex-direction: column;
        flex-grow: 1;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
        color: #97b3cb;
        .center-circle {
          margin-right: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 150px;
          width: 150px;
          border-radius: 50%;
          aspect-ratio: 1/1;
          border: 5px solid #7ed0ff;
          border-bottom-color: transparent;
          position: relative;
          .circle-text {
            display: flex;
            height: 45%;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding-top: 10px;
            .num {
              color: #7ed0ff;
              font-size: 18px;
              transform: translateY(0.14rem);
            }
            .unit {
              color: #97b3cb;
              font-size: 20px;
            }
          }
          &::before {
            position: absolute;
            content: "";
            display: block;
            top: 50%;
            left: 50%;
            aspect-ratio: 1/1;
            border-radius: 50%;
            height: 120px;
            width: 120px;
            border: 3px solid #95f204;
            border-bottom-color: transparent;
            transform: translate(-50%, -50%);
          }
          &::after {
            position: absolute;
            content: "发电功率";
            bottom: 0;
            left: 50%;
            font-size: 16px;
            transform: translateX(-50%);
            color: #97b3cb;
          }
        }
        .bottom-left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          color: #fff;
          .bottom-left-item {
            display: flex;
            align-items: center;
            width: 200px;
            .bottom-left-item-color {
              color: #02a7f0;
              font-size: 20px;
              margin-right: 10px;
            }
          }
        }
      }
      .item-fourth {
        display: flex;
        align-items: center;
        height: 160px;
        .fourth-left {
          height: 100%;
          width: 100%;
        }
        ul.fourth-items {
          box-sizing: border-box;
          padding: 10px;
          margin: 0;
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          li.item {
            border: none;
            margin: 0 !important;
            .label {
              color: #97b3cb;
              padding-bottom: 2px;
              font-size: 16px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              word-break: break-all;
            }
            /deep/.progress {
              color: red;
              .el-progress-bar__outer {
                background-color: #132c45 !important;
                border: 1px solid #02a7f0 !important;
              }
            }
          }
        }
      }
    }
  }

    .list {
    // height: 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: #fff;

    div {
      margin-top: 10px;
    }
    // height: 80px;
  }
  .item {
    // margin-top: 10px;
    width: 47%;
    // height: 30px;
    .item-title {
      display: flex;
      justify-content: space-between;
      color: #fff;
      margin-bottom: 10px;
    }
  }
}
.progress-bar {
  width: 170px; /* 进度条整体宽度 */
  background-color: #ddd; /* 进度条背景颜色 */
  border-radius: 10px; /* 可选：圆角边框 */
}

.progress {
  // width: 50%; /* 进度条的完成部分 */
  height: 10px; /* 进度条的高度 */
  background-color: #4caf50; /* 进度条的前景色 */
  border-radius: 10px; /* 可选：圆角边框 */
}

</style>
